import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import ClosedTab from "./js/ClosedTab"
import Locked from './js/Locked'
import Blocked from './js/Blocked'
import Paused from './js/Paused'
import BlockedDetail from './js/BlockedDetail'


function App() {
  return (
    <Router>
      <Switch>
        <Route path="/check" render={() => "OK"}></Route>
        <Route path="/locked"><Locked /></Route>
        <Route path="/closed_tab"><ClosedTab /></Route>
        <Route path="/blocked"><Blocked /></Route>
        <Route path="/paused"><Paused /></Route>
        <Route path="/detail"><BlockedDetail /></Route>
      </Switch>
    </Router>
  );
}

export default App
